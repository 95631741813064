<template>
    <VueQrcode :value="qrValue" :options="{ width: 250 }"></VueQrcode>
</template>

<script>
import store from '@/store'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { getEncryptedToken } from '@/utils/AuthService'

export default {
    name: 'BoxQRCode',
    components: {
        VueQrcode
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            userInfo: null,
            interval: null,
        }
    },
    computed: {
        qrValue() {
            this.updateUserInfo()
            let value = Object.assign({}, this.userInfo, this.content)
            return JSON.stringify(value)
        }
    },
    methods: {
        updateUserInfo() {
            let home = store.getters.pageHome
            let lohref = location.href
            let client_id = store.getters.clientId
            this.userInfo = {token: getEncryptedToken(), client_id: client_id, uid: store.getters.user.id}
            if (store.getters.user.isGuest) this.userInfo.home = home
            if (home === 0 && lohref.indexOf('takeout') !== -1)  this.userInfo.gto = client_id
            store.commit('setUserInfo', this.userInfo)
            console.log(store.getters.clientId)
            this.startInterval()
        },
        startInterval() {
            if (!this.interval) this.interval = setInterval(this.updateUserInfo, 300000)
        }
    },
    deactivated() {
        clearInterval(this.interval)
        this.interval = null
    }
}
</script>

<style scoped>
</style>
